import type { UIRouter } from '@wix/tpa-router';

export function configureRouter(router: UIRouter) {
  router.urlService.rules.initial({
    state: 'members.groups.my-groups',
    params: { memberId: '' },
  });
  router.urlService.rules.otherwise({
    state: 'members.groups.my-groups',
    params: { memberId: '' },
  });
}
