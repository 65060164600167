import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';

import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';

import { initializeRouter, registerApplicationRoutes } from 'controller/router';
import { initializeStore, persistStore } from 'controller/store';
import { bindViewModel, initializeViewModel } from 'controller/view-model';
import { resolvables } from 'controller/resolvables';
import { initializeSettingsEventHandler } from 'controller/tpa-settings-events';

import { resolve } from './resolve';
import { configureRouter } from './config';

export const controller: CreateControllerFn = async (params) => {
  const router = initializeRouter(params);
  const settingsEvents = initializeSettingsEventHandler(router, params);
  const widgetPluginService = createMembersAreaWidgetPluginService();

  const initialise = async () => {
    const store = initializeStore(params);
    const vm = await initializeViewModel(router, store, params);

    configureRouter(router);
    registerApplicationRoutes(router, vm, store, params);

    bindViewModel(vm, store, params);
    bindRouter(router, params.flowAPI);

    await resolvables(params, resolve(router, params));

    persistStore(store, params);
  };

  return {
    async pageReady() {
      const isRendered = widgetPluginService.getIsRendered();

      if (isRendered) {
        await initialise();
      }
    },
    updateConfig($w, updatedConfig) {
      settingsEvents.notify(updatedConfig.publicData.COMPONENT || {});
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
    exports: () => {
      return createWidgetPluginExports(widgetPluginService, initialise);
    },
  };
};
